export const usePostMessage = () => {
  const POST_MESSAGES = {
    LOGIN: 'switch_to_native.login',
    CHANGE_APP_LANGUAGE: 'switch_to_native.change_app_language',
    MY_TICKETS: 'switch_to_native.my_tickets',
    MY_PROFILE: 'switch_to_native.profile',
  }

  const postMessage = (message) => {
    // assumes webview is creating mobileAppMessageChannel object
    if (typeof mobileAppMessageChannel !== 'undefined') {
      mobileAppMessageChannel.postMessage(message)
      console.log(`Posted Message: ${message}`)
    } else {
      console.error('mobileAppMessageChannel is not defined')
    }
  }

  const postMessageLogin = () => {
    postMessage(POST_MESSAGES.LOGIN)
  }
  const postMessageChangeAppLanguage = () => {
    postMessage(POST_MESSAGES.CHANGE_APP_LANGUAGE)
  }
  const postMessageMyTickets = () => {
    postMessage(POST_MESSAGES.MY_TICKETS)
  }
  const postMessageMyProfile = () => {
    postMessage(POST_MESSAGES.MY_PROFILE)
  }

  return {postMessageLogin, postMessageChangeAppLanguage, postMessageMyTickets, postMessageMyProfile}
}
